<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-account-group-outline</v-icon>
        Produtos
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-card flat>
          <v-card-title class="align-end gap">
            <v-text-field
              style="max-width: 400px"
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              clearable
              dense
              hide-details
            ></v-text-field>

            <v-select
              v-model="tipo"
              :items="tipos"
              label="Tipo"
              hide-details
              clearable
              dense
              style="max-width: 400px"
              item-value="id"
              item-text="value"
            >
            </v-select>

            <v-select
              v-model="categoria"
              :items="categorias"
              label="Categoria"
              hide-details
              clearable
              dense
              style="max-width: 400px"
              item-value="id"
              item-text="value"
            >
            </v-select>

            <v-btn
              :to="{ path: '/loja/produtos/adicionar' }"
              align="center"
              color="xbColor"
              class="white--text"
            >
              <v-icon left> mdi-plus</v-icon>
              Adicionar
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :search="search"
              :items="produtos"
              :loading="loading"
              :items-per-page="10"
              :sort-desc="true"
              class="data-tables data-tables__row-click"
              @click:row="goToProduto"
            >
              <!-- ID -->
              <template v-slot:item.id="{ item }">
                {{ item.id }}
              </template>

              <!-- Descrição -->
              <template v-slot:item.descricao="{ item }">
                <div class="d-flex align-center justify-space-between">
                  {{ item.descricao }}
                </div>
              </template>

              <!-- Tipo -->
              <template v-slot:item.tipo="{ item }">
                <div class="d-flex align-center justify-space-between">
                  {{ tipos.find((tipo) => tipo.id === item.tipo)?.value }}
                </div>
              </template>

              <!-- Valor -->
              <template v-slot:item.valor="{ item }">
                <div class="d-flex align-center justify-space-between">
                  R$ {{ item.valor | currency }}
                </div>
              </template>

              <!-- Created At -->
              <template v-slot:item.created_at="{ item }">
                <div class="bg-red">
                  {{
                    dateFormat(
                      new Date(item.created_at).toISOString(),
                      "dd/MM/yyyy"
                    )
                  }}
                </div>
              </template>

              <!-- desativar produto -->
              <template v-slot:item.status="{ item }">
                <v-btn @click.stop="toggleDesativar(item)" icon>
                  <v-icon
                    :color="item.status ? 'green darken-2' : 'grey lighten-1'"
                  >
                    {{
                      item.status
                        ? "mdi-checkbox-marked-circle"
                        : "mdi-close-circle"
                    }}
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { allCategorias, allProdutos, updateProduto } from "@/api/admin/loja.js";
import { selectTipo } from "@/filters";
import { dateFormat } from "@/filters";

export default {
  name: "produtos",

  data() {
    return {
      loading: true,
      loadingDispositivos: false,
      produtosData: [],
      produtos: [],
      categorias: [],
      tipos: selectTipo,
      search: "",
      tipo: null,
      categoria: null,
    };
  },

  components: {},

  computed: {
    headers() {
      return [
        {
          text: "ID",
          value: "id",
          width: "80px",
        },
        {
          text: "Descrição",
          value: "descricao",
        },
        {
          text: "Tipo",
          value: "tipo",
          width: "8rem",
        },
        {
          text: "Categoria",
          value: "categoria.descricao",
          width: "8rem",
        },
        {
          text: "Valor",
          value: "valor",
          width: "8rem",
        },
        {
          text: "Criado em",
          value: "created_at",
          width: "8rem",
        },
        {
          text: "Ativo",
          value: "status",
          width: "80px",
          sortable: false,
          align: "center",
        },
      ];
    },
  },

  watch: {
    url: {
      handler() {
        this.getProdutos();
      },
      immediate: true,
    },
    categoria() {
      this.filtrarProdutos();
    },
    tipo() {
      this.filtrarProdutos();
    },
  },

  methods: {
    getProdutos() {
      this.loading = true;
      allProdutos()
        .then((response) => {
          this.produtosData = response;
          this.produtos = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async getCategorias() {
      const response = await allCategorias();
      this.categorias = response.map((item) => {
        return {
          id: item.id,
          value: item.descricao,
        };
      });
    },

    filtrarProdutos() {
      let produtosfiltrados = this.produtosData;
      if (this.categoria) {
        produtosfiltrados = produtosfiltrados.filter(
          (item) => item.categoria_id === Number(this.categoria)
        );
      }
      if (this.tipo) {
        produtosfiltrados = produtosfiltrados.filter(
          (item) => item.tipo === this.tipo
        );
      }
      this.produtos = produtosfiltrados;
    },

    goToProduto(item) {
      this.$router.push({ path: `/loja/produtos/${item.id}` });
    },

    toggleDesativar(item) {
      item.status = item.status ? false : true;
      this.desativarProduto(item);
    },

    desativarProduto(item) {
      updateProduto(item, item.id)
        .then(() => {
          this.$toast.success("Status atualizado com sucesso");
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    dateFormat,
  },
  mounted() {
    this.getCategorias();
  },
};
</script>

<style scoped>
.gap {
  gap: 1rem;
}
</style>
